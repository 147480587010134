.page-header {
  min-height: 10rem;
  z-index: 2;
  @include tablet {
    display: flex;
  }

  @include desktop {
    max-width: 100%;
  }

  &__user {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.6rem;
    width: 100%;
    align-items: center;
    gap: 1rem;

    @include tablet {
      width: max-content;
      margin: 0rem;
      margin-right: auto;
    }
  }
  &__title {
    color: $hextechwhite;
    font-size: 1.5rem;
  }
}