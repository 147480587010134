*,
*::before,
*::after {
  box-sizing: border-box;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
}

.page {
  font-size: 62.5%;

  &__body {
    font-family: "Cairo", cursive;
    font-size: 1.6rem;
    line-height: 1.3;
    margin: 0;
    min-height: 100vh;
  }

  &__main {
    height: calc(100vh - 124px);
    padding-top: 32px;
    padding-bottom: 24px;

    &::before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include tablet {
      height: calc(100vh - 132px);
    }
  }
}

.icon {
  vertical-align: bottom;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}

.container {
  width: 90vw;
  max-width: 1280px;
  margin: 0 auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 100s ease-in-out 0s;
  -webkit-text-fill-color: $hextechgold2 !important;
}
