.builds-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--form-background);
  border-radius: 8px;
  overflow-x: scroll;
  flex-grow: 1;
  &::-webkit-scrollbar {
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--form-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--form-background);
  }

  &__list {
    display: grid;
    grid-auto-columns: 4.8rem;
    grid-auto-flow: column;
    gap: 0.8rem;
    font-size: 1.2rem;
    color: $hextechwhite;

    @include tablet {
      grid-auto-columns: 6.4rem;
      gap: 1.6rem;
    }
  }

  &__name {
    background: transparent;
    border: none;
    padding: 0;
    font-weight: bold;
    border-bottom: 1px solid $hextechgold2;
    color: white;
    font-size: 1.5rem;
    &::placeholder {
      color: rgba($hextechgold2, 64%);
    }

    &:hover {
      outline: none;
    }
  }

  button[type="submit"] {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;

    @include desktop {
      position: static;
    }
  }

  &__buttons {
    @include desktop {
      display: flex;
      gap: 0.8rem;
      justify-content: flex-end;
    }
  }

  &__hero {
    margin: 0;
  }
}
