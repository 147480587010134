.spells-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 1.6rem;
  grid-auto-flow: column;
  grid-auto-columns: 5rem;
  overflow-x: scroll;
  padding-bottom: 0.8rem;
  margin-bottom: -0.8rem;

  @include tablet {
    grid-auto-columns: 6.4rem;
  }
  &::-webkit-scrollbar {
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($hextechgold2, 32%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($hextechgold2, 32%);
  }
}
