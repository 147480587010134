.items-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 1.6rem;
  padding-right: 0.8rem;
  margin-right: -0.8rem;
  overflow-y: scroll;
  height: 100vw;
  @include tablet {
    grid-template-columns: repeat(auto-fit, minmax(6.4rem, 1fr));
  }

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($hextechgold2, 32%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($hextechgold2, 32%);
  }

  @include desktop {
    height: 100%;
  }
}
