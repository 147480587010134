.board {
  position: relative;
  border-radius: 8px;
  padding: 1.6rem;
  color: $hextechgold2;
  background-color: var(--board-background);
  width: 100%;
  height: 100%;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--board-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--board-background);
  }

  &__title {
    position: absolute;
    top: 0;
    left: 0.8rem;
    transform: translateY(-100%);
    margin: 0;
    font-size: 1.8rem;
    color: $hextechblue1;
    backdrop-filter: blur(20px);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
  }

  &__inner {
    max-width: 100%;
    padding-right: 0.8rem;
    max-height: 100%;
    overflow-y: scroll;
  }
}
