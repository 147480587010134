.text-field {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 4.8rem;
  align-items: center;
  margin-top: 1.4rem;
}

.text-field__label {
  position: absolute;
  top: 50%;
  left: 1.6rem;
  font-size: 1.6rem;
  line-height: 1;
  transform: translateY(-50%);
  transition: 0.3s;
  
  input:focus + &,
  input:not(:placeholder-shown) + & {
    top: -1.4rem;
    left: 0.8rem;
    transform: translateY(0);
    font-size: 1.2rem;
  }
}
.text-field__input {
  display: flex;
  width: 100%;
  height: 4.8rem;
  font-size: 1.6rem;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 1.6rem;
  font-weight: bold;

  &:focus {
    outline: none;
  }
  &:valid {
    border: 3px solid green;
  }
  &:invalid {
    border: 3px solid $hextechgold2;
  }
  &::placeholder {
    color: rgba($hextechgold2, 0.5);
    font-size: 0;
    transition: 0.3s;
  }

  &:focus::placeholder {
    font-size: 1.6rem;
  }
}
