.card {
  margin: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
  gap: 0.4rem;
  cursor: pointer;

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  &__image {
    display: flex;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    text-indent: -1000%;
    background-color: rgba($hextechgold2, 32%);
    border-radius: 4px;
  }

  @include tablet {
    &:hover &__modal {
      position: fixed;
      top: 100%;
      left: 100%;
      margin: 0;
      z-index: 2;
      transform: translate(-100%, -100%);
      border: none;
      width: 100%;
      max-width: 42rem;
      display: block;
    }
  }
}
