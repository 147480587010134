.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 1.6rem;
  color: $hextechgold2;
  background-color: rgba(#000000, 0.5);
  backdrop-filter: blur(10px);
  padding: 2.4rem;
  max-width: 42rem;

  @include tablet {
    padding: 3.2rem;
  }

  @include desktop {
    padding: 4rem;
  }
}
