.login-page {
  display: flex;
  padding-top: 0;
  &::before {
    background-image: url('../../../assets/img/login-page.jpeg');
  }
  &__title {
    margin-left: 1rem;
  }
}
