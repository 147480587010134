     .about-page {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @include tablet {
    flex-direction: row;
  }
  &__container {
    position: relative;
    border-radius: 8px;
    padding: 1.6rem;
    padding-right: 0.8rem;
    color: rgb(0 0 0);
    background-color: rgb(255 255 255 / 25%);
    backdrop-filter: blur(5px);
    width: 100%;
  }
  &__text {
    font-size: 1.7rem;
    @include tablet {
      font-size: 1.9rem;
      font-weight: 600;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  &::before {
    background-image: url("../../../assets/img/about-page.jpg");
  }
}
