.builds-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--form-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $hextechgold2;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;

    @include tablet {
      align-items: center;
      flex-direction: row;
      gap: 2.4rem;
    }
  }
  &__button {
    margin-top: 1.8rem;
    margin-right: auto;
    margin-left: auto;
  }
}
