.heroes-selected {
  display: flex;
  flex-direction: column-reverse;
  gap: 3.2rem;
  height: auto;
  padding-bottom: 32rem;

  &::before {
    background-image: url('../../../assets/img/hero-selected-page.jpg');
  }

  @include desktop {
    flex-direction: row;
    max-width: 100%;
    overflow: hidden;
    align-items: flex-start;
    height: calc(100vh - 132px);
    gap: 1.6rem;
    padding-bottom: 0rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @include desktop {
      width: 35%;
    }
  }

  &__builds-card {
    position: fixed;
    left: 0;
    bottom: 0;
    background-image: url('../../../assets/img/form-back.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    z-index: 2;
    width: 100%;

    @include desktop {
      position: static;
      background-image: unset;
      background-color: rgba(60, 32, 31, 52%);
      border-radius: 8px;
      z-index: 0;
      width: auto;
      &::-webkit-scrollbar {
        height: 0.8rem;
      }
    
      &::-webkit-scrollbar-track {
        background-color: rgba($hextechgold2, 32%);
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: rgba($hextechgold2, 32%);
      }
    }
  }
}
