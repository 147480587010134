.heroes-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 1.6rem;
  padding-right: 0.8rem;
  margin-right: -0.8rem;
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--form-webkit);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--form-webkit);
  }

  &__link {
    display: block;
  }
}
