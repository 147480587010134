.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: $hextechgold2;
  font-size: 1.2rem;
  color: $hextechblack;
  backdrop-filter: blur(15px);
  width: max-content;
  height: 2.5rem;
  padding: 0 1.6rem;
  border-radius: 4px;
  text-transform: uppercase;
  border: 2px solid $hextechgold2;
  box-shadow: 0 4px 8px rgba($hextechgold2, 0.5);
  cursor: pointer;
  transition: 0.3s;
  @include tablet {
    font-size: 1.6rem;
    height: 4rem;
  }

  &:hover {
    box-shadow: none;
    background-color: transparent;
    color: $hextechwhite;
  }

  &--fullwidth {
    width: 100%;
    height: 4rem;
  }
}
