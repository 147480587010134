.hero-card {
  display: flex;
  gap: 1.6rem;
  color: rgb(255, 255, 255);
  align-items: flex-start;
  background-color: rgba(60, 32, 31, 52%);
  border-radius: 8px;
  padding: 0.8rem;
  min-width: 24rem;
  order: 1;

  @include desktop {
    flex-direction: column;
    width: 10rem;
    align-items: center;
    padding: 1.6rem;
    order: unset;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__title {
    margin: 0;
    font-size: 1.6rem;
  }

  &__blurb {
    margin: 0;
    font-size: 1.4rem;
  }
}
