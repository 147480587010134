.page-navigation {
  display: flex;
  gap: 1rem;
  &__item {
    background-color: $hextechgold2;
    border-radius: 4px;
    width: 100%;
    color: $hextechblack;
    border: 2px solid $hextechgold2;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      // box-shadow: none;
      backdrop-filter: blur(10px);
      background-color: transparent;
      color: $hextechwhite;
      box-shadow: 0 4px 8px rgba($hextechgold2, 0.5);
    }

    @include tablet {
      align-self: flex-start;
      margin: 10px 0;
    }
    &--current {
      backdrop-filter: blur(10px);
      background-color: transparent;
      color: $hextechwhite;
      box-shadow: 0 4px 8px rgba($hextechgold2, 0.5);
      text-decoration-line: underline;
      text-underline-offset: 2px;
    }
  }
  &__link {
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: inherit;
    padding: 0.5rem 16px;
    box-shadow: 0 4px 8px rgba($hextechgold2, 0.5);
    @include tablet {
      padding: 1rem 3rem;
      font-size: 1.6rem;
    }
  }
}
