.not-found {
    color: $hextechwhite;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    &::before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url('../../../assets/img/not-found-page.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__subheader {
      opacity: 0;
      animation: show 0.75s ease-out 0.5s forwards;
    }
    &__header {
        opacity: 0;
        font-size: 10rem;
        margin: 0;
        font-weight: 800;
        letter-spacing: 2rem;
        background: url('../../../assets/img/signup-page.jpeg');
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        animation: show 0.75s ease-out 2.5s forwards;
        background-position: top left;
        @include tablet {
            font-size: 16rem;
        }
    }
    &__text {
      opacity: 0;
      font-size: 1.4rem;
      animation: show 0.75s ease-out 4s forwards;
      @include tablet {
        font-size: 2rem;
      }
    }

    &__link {
      opacity: 0;
      animation: show 0.75s ease-out 6s forwards;
      font-weight: 600;
      font-size: 1.3rem;
      @include tablet {
        font-size: 1.8rem;
      }
    }
  }

  @keyframes show {
    100%{
      opacity: 1;
    }
  }
